<template>
  <div></div>
</template>
<script>
export default {
  name: 'WhitelistEmailWithdrawal',

  data() {
    return {
      input:{
        email:'',
        code:''
      },
    }
  },
  mounted:function(){
    this.whitelistEmail();
  },
  methods:{
    whitelistEmail() {
      this.input.email = this.$route.query.email;
      this.input.code = this.$route.query.token;
      this.$http.post(this.createApiUrlChestNut('whitelisted-addresses/verify/email'), this.input).then(() =>
        {
          this.$router.push("/whitelist-success").catch(() => {});
        },() => {
          this.$router.push("/whitelist-expired").catch(() => {});
        });
    },
  },
}
</script>
